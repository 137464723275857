import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import {
  BreadCrumb,
  Section,
  SectionDescription,
  SectionPageTitle,
} from "../components/Section"
import ProductSection from "../sections/Product/ProductSection"
import QuotePopup from "../components/QuotePopup"

const ShopBySizeTemplate = ({ data, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const { 
    contentfulShopBySize,
    allContentfulProduct: productsData
  } = data

  let allSubCategories = []
  productsData.edges.forEach(function (item) {
    item.node.productSubCategories.forEach(function (node) {
      allSubCategories.push(node.name)
    })
  })
  const allProductApplications = data.allContentfulProductApplication.edges
  const allRoofStyles = data.allContentfulRoofStyle.edges

  const { title, details, metaTitle, metaDescription } = contentfulShopBySize
  return (
    <Layout location={location}>
      <Seo title={metaTitle} description={metaDescription.metaDescription}  />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{title}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="90px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="section-size"
      >
        <div className="container">
          <SectionPageTitle>{title}</SectionPageTitle>
          <SectionDescription
            dangerouslySetInnerHTML={{
              __html: details?.childMarkdownRemark?.html || "",
            }}
          />
        </div>
        <ProductSection
          limit={false}
          sectionShadow="Explore Our Top Selling Buildings"
          sectionTitle="Explore Our Top Selling Buildings"
          data={productsData}
          buildingType={allSubCategories}
          buildingApplication={allProductApplications}
          allRoofStyles={allRoofStyles}
          openQuoteModal={() => setQuoteVisible(true)}
        />
      </Section>
      <QuotePopup
        states={data.allContentfulState.edges}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        location={location}
      />
    </Layout>
  )
}

export default ShopBySizeTemplate

export const pageQuery = graphql`
  query ShopBySizeTemplateQuery($id: String!, $title: String!) {
    contentfulShopBySize(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      type
      details {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(filter: {shopBySizeType: {eq: $title}}) {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulSubCategory {
      edges {
        node {
          name
          mainCategory {
            name
          }
        }
      }
    }
    allContentfulProductApplication {
      edges {
        node {
          name
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
